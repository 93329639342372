import { Section, Row, color, ResponsiveBox } from './Global'
import styled, { css } from 'styled-components'
import { useEffect, useRef } from 'react'
import { useContext } from 'react'
import { Variable } from '../../App'
import Animation from './Animation'

const TextAlign = styled.div`
	width: 100%;
	height: ${(props) => (props.isMobile ? 'auto' : '65vh')};
	padding: 40px 20px;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	align-items: ${(props) => props.align || 'center'};
	z-index: 1;

	${(props) =>
		props.left &&
		css`
			text-align: right;
		`}

	${(props) =>
		props.right &&
		css`
			text-align: left;
		`}
		
	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-size: cover;
			background-repeat: no-repeat;
			background-position: ${props.position || '0 80%'};
		`}

	${(props) =>
		props.hovering &&
		css`
			h6 {
				color: ${color.white};
				transition: 0.35s ease-out;
			}

			h5 {
				color: ${color.active};
				transition: 0.35s ease-out;
			}

			p {
				color: ${color.active};
				transition: 0.35s ease-out;
			}

			a {
				color: ${color.white} !important;
				transition: 0.35s ease-out;
			}
		`}

	h6 {
		margin-bottom: 10px;
	}

	h5 {
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 20px;
	}

	a {
		width: auto;
		height: auto;
		color: ${color.active};
		text-decoration: underline;
		position: absolute;
		${(props) =>
			props.left &&
			css`
				right: 20px;
			`}
		${(props) =>
			props.right &&
			css`
				left: 20px;
			`}
		bottom: 30px;
	}
`

const Example = ({
	left,
	right,
	opacity = '15%',
	positionLeft,
	positionRight,
}) => {
	const { isMobile, hovering, setHovering } = useContext(Variable)
	const data = {
		isMobile,
		setHovering,
		hovering,
		left,
		right,
		positionLeft,
		positionRight,
	}

	return (
		<Section noPadding>
			<ResponsiveBox noPadding>
				<ExampleTemplate opacity={opacity} data={data} />
			</ResponsiveBox>
		</Section>
	)
}

function ExampleTemplate({ data }) {
	const {
		isMobile,
		setHovering,
		hovering,
		left,
		right,
		positionLeft,
		positionRight,
	} = data
	const box = useRef({})

	useEffect(() => {
		if (isMobile) {
			const target = box.current
			const top = target.top.getBoundingClientRect()
			const bottom = target.bottom.getBoundingClientRect()
			const scroll = () => {
				const firstTargetTop = top.top
				const firstTargetBottom = top.top + top.height
				const secondTargetTop = bottom.top
				const secondTargetBottom = bottom.top + bottom.height
				const ourPos = window.scrollY + window.innerHeight / 2

				if (ourPos >= firstTargetTop && ourPos < firstTargetBottom) {
					setHovering({ left: true, right: false })
				} else if (ourPos >= secondTargetTop && ourPos < secondTargetBottom) {
					setHovering({ left: false, right: true })
				} else {
					setHovering({ left: false, right: false })
				}
			}
			window.addEventListener('scroll', scroll)
			return () => window.removeEventListener('scroll', scroll)
		}
	}, [isMobile, setHovering])
	return (
		<>
			<Row
				width={isMobile ? '100%' : '50%'}
				height={'100%'}
				noPadding
				ref={(ref) => (box.current.top = ref)}
			>
				<Animation
					animateIn={isMobile ? 'animate__slideInDown' : 'animate__slideInLeft'}
					duration={0.5}
				>
					<TextAlign
						left
						src={left.src}
						hovering={hovering.left}
						position={isMobile && (positionLeft || '25%')}
						align={'flex-end'}
						isMobile={isMobile}
						onMouseEnter={() =>
							!isMobile && setHovering({ left: true, right: false })
						}
						onMouseLeave={() =>
							!isMobile && setHovering({ left: false, right: false })
						}
					>
						{left.child}
					</TextAlign>
				</Animation>
			</Row>
			<Row
				width={isMobile ? '100%' : '50%'}
				height={'100%'}
				noPadding
				ref={(ref) => (box.current.bottom = ref)}
			>
				<Animation
					animateIn={isMobile ? 'animate__slideInUp' : 'animate__slideInRight'}
					duration={0.5}
				>
					<TextAlign
						right
						src={right.src}
						position={isMobile && (positionRight || '75%')}
						hovering={hovering.right}
						align={'flex-start'}
						isMobile={isMobile}
						onMouseEnter={() => setHovering({ left: false, right: true })}
						onMouseLeave={() => setHovering({ left: false, right: false })}
					>
						{right.child}
					</TextAlign>
				</Animation>
			</Row>
		</>
	)
}

export default Example
