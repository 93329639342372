import {
	Center,
	Col,
	color,
	ColTarget,
	Container,
	HeadText,
	Input,
	IntroBanner,
	OutroBanner,
	Paragraph,
	Row,
	Section,
	SubText,
	ResponsiveBox,
	BreakLine,
	Filter,
} from './utilities/Global'
import banner from '../assets/contact/banner.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFacebook,
	faInstagram,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import styled, { css } from 'styled-components'
import { useContext } from 'react'
import { Variable } from '../App'
import Button from './utilities/Button'
import { useState, useEffect, useRef } from 'react'
import { useEmail } from '../customhooks'

export default function Contact() {
	const { isMobile } = useContext(Variable)
	const user = useRef({})
	const timeout = useRef()
	const [mount, setMount] = useState(false)

	const { loading, submitted, error, sendEmail } = useEmail(
		process.env.REACT_APP_FORM_ENDPOINT
	)

	const onChange = (e) => {
		if (e.target.name === 'Name') {
			user.current.name = e.target.value
		} else if (e.target.name === 'Email') {
			user.current.email = e.target.value
		} else if (e.target.name === 'Message') {
			user.current.message = e.target.value
		}
	}

	useEffect(() => {
		setTimeout(() => setMount(submitted), 500)

		if (mount) {
			timeout.current = setTimeout(() => setMount(false), 4000)
		}
	}, [submitted, mount])

	useEffect(() => {
		const click = () => {
			clearTimeout(timeout.current)
			setMount(false)
		}
		window.addEventListener('click', click)

		return () => window.removeEventListener('click', click)
	}, [])

	const onSubmit = (e) => {
		e.preventDefault()
		sendEmail(user.current)
	}

	const iconSize = {
		height: '35px',
		width: 'auto',
		color: color.active,
		margin: '0 10px 0 0',
	}

	document.title = 'Contact Us - VW Inter Trade'

	return (
		<>
			<Section noPadding>
				<IntroBanner src={banner} position={'40%'}>
					<Center height={'70vh'}>
						<SubText>Send us a message, we'd love to</SubText>
						<HeadText>Hear from you</HeadText>
						<Filter opacity={'0.3'} />
					</Center>
				</IntroBanner>
				<OutroBanner>
					<Container>
						<Row>
							<ColTarget
								as={'a'}
								href={'https://www.facebook.com/SnowThailandTH'}
							>
								<FontAwesomeIcon icon={faFacebook} />
								<h6>Facebook</h6>
								<span>
									Like our Facebook page for awesome deals & new arrivals
								</span>
							</ColTarget>
							<ColTarget
								as={'a'}
								href={'https://www.instagram.com/snowthailand.official/'}
							>
								<FontAwesomeIcon icon={faInstagram} />
								<h6>Instagram</h6>
								<span>
									Hashtag #Vwinter to be in with a chance to win freebies.
								</span>
							</ColTarget>
						</Row>
					</Container>
				</OutroBanner>
			</Section>

			<BreakLine />

			<Section noPadding>
				<ResponsiveBox noPadding reverse>
					<iframe
						title="google-map"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.748879060715!2d100.52572781483026!3d13.733647790359205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e299db8ae6d4dd%3A0xf0e5115dec9aff3b!2sMitrtown%20Office%20Tower!5e0!3m2!1sth!2sth!4v1674996073894!5m2!1sth!2sth"
						style={{
							border: 0,
							width: isMobile ? '100%' : '50%',
							height: isMobile ? '45vh' : '70vh',
						}}
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					/>

					<Box isMobile={isMobile} details>
						<Col
							width={'60%'}
							align={isMobile ? 'center' : 'baseline'}
							{...(!isMobile && { padding: '0 0 0 30px' })}
						>
							<SubText>We Are Together</SubText>
							<HeadText>Contact Us</HeadText>
							<Paragraph {...(isMobile && { textAlign: 'center' })}>
								VW Inter Trade Co., LTD. No. S25012, 25th Floor, Mitrtown Office
								Tower, 944 Rama 4 Road, Wangmai, Pathumwan, Bangkok 10330
							</Paragraph>
						</Col>
					</Box>
				</ResponsiveBox>
			</Section>

			<BreakLine />

			<Section noPadding>
				<ResponsiveBox noPadding reverse>
					<Box isMobile={isMobile}>
						<Col {...(isMobile && { padding: '20px 0' })}>
							<Info isMobile={isMobile}>
								<Row>
									<FontAwesomeIcon style={iconSize} icon={'location-dot'} />
									<HeadText fontSize={'3vw'} active>
										Address
									</HeadText>
								</Row>
								<Paragraph>
									No. S25012, 25th Floor, Mitrtown Office Tower, 944 Rama 4
									Road, Wangmai, Pathumwan, Bangkok 10330
								</Paragraph>
							</Info>
							<Info isMobile={isMobile}>
								<Row>
									<FontAwesomeIcon style={iconSize} icon={'envelope'} />
									<HeadText fontSize={'3vw'} active>
										Email
									</HeadText>
								</Row>
								<Paragraph>info@vw-intertrade.com</Paragraph>
							</Info>
							<Info isMobile={isMobile}>
								<Row>
									<FontAwesomeIcon style={iconSize} icon={'phone'} />
									<HeadText fontSize={'3vw'} active>
										Telephone
									</HeadText>
								</Row>
								<Paragraph>+66 2079 1824</Paragraph>
							</Info>
						</Col>
					</Box>
					<Box isMobile={isMobile} white>
						<Form onSubmit={(e) => onSubmit(e)}>
							<Col
								justify="center"
								align={'center'}
								{...(isMobile && { padding: '20px 0' })}
							>
								<HeadText
									fontSize={isMobile ? '40px !important' : '30px'}
									margin={'0 0 10px 0'}
									color={color.black}
									noBold
								>
									Send us a message
								</HeadText>
								<InputRequired
									name={'Name'}
									isMobile={isMobile}
									placeholder={'Name'}
									type={'text'}
									ref={(ref) => (user.current.name = ref)}
									onChange={(e) => onChange(e)}
									spellCheck={false}
									required
								/>
								<InputRequired
									name={'Email'}
									isMobile={isMobile}
									placeholder={'Email'}
									type={'email'}
									ref={(ref) => (user.current.email = ref)}
									onChange={(e) => onChange(e)}
									required
								/>
								<InputRequired
									name={'Message'}
									isMobile={isMobile}
									as={'textarea'}
									placeholder={'Message'}
									type={'text'}
									ref={(ref) => (user.current.message = ref)}
									onChange={(e) => onChange(e)}
									required
								/>
								<Button submit type={'submit'} inverse>
									Send
								</Button>
							</Col>
						</Form>
					</Box>
				</ResponsiveBox>
			</Section>

			<BreakLine />

			{submitted && (
				<PopupBox submitted>
					Hi {user.current.name}. Your form has been submited! We will reply
					back as soon as possible.
				</PopupBox>
			)}
			{loading && <PopupBox loading>'Email is being sent now...'</PopupBox>}
			{error && (
				<PopupBox error>Sonething went wrong! Please try again.</PopupBox>
			)}
		</>
	)
}

const InputRequired = ({ ...props }) => {
	const [focus, setFocus] = useState(false)
	const removeAstral = (e) => {
		if (e.target.value) {
			setFocus(true)
		} else {
			setFocus(false)
		}
	}
	return (
		<Required as={'div'} focus={focus}>
			<Input
				onInput={(e) => removeAstral(e)}
				onBlur={(e) => removeAstral(e)}
				onFocus={() => setFocus(true)}
				{...props}
			/>
			{!focus && <span>*</span>}
		</Required>
	)
}

const Required = styled.div`
	width: 100%;
	height: auto;
	position: relative;

	span {
		color: red;
		position: absolute;
		top: 5px;
		right: 5px;
		${(props) =>
			props.focus &&
			css`
				opacity: 0;
			`}
	}
`

const PopupBox = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	color: #f7f7f7;
	${(props) =>
		props.submitted &&
		css`
			background-color: #5cb85c;
		`}
	${(props) =>
		props.loading &&
		css`
			background-color: #0275d8;
		`}
	${(props) =>
		props.error &&
		css`
			background-color: #d9534f;
		`}
	text-align: center;
	font-size: 10px;
`

const Form = styled.form`
	width: 65%;
	height: auto;
	margin: auto;
`

const Info = styled.div`
	width: ${(props) => (props.isMobile ? '100%' : '30%')};
	text-align: center;

	span {
		color: ${color.active};
		font-size: ${(props) => (props.isMobile ? '20px' : '30px')};
		margin-left: 10px;
	}

	svg {
		height: ${(props) => (props.isMobile ? '20px' : '30px')};
		color: ${color.active};
	}

	${(props) =>
		props.isMobile &&
		css`
			p {
				width: 70%;
				margin: auto;
			}
		`}
`

const Box = styled.div`
	${(props) =>
		props.isMobile
			? css`
					width: ${props.width || '100%'};
					height: ${props.height || 'auto'};
					justify-content: center;
			  `
			: css`
					width: ${props.width || '50%'};
					height: ${props.height || '70vh'};
			  `}
	background-color: ${(props) => (props.white ? color.white : color.primary)};
	position: relative;
	display: flex;
	align-items: center;
	${(props) =>
		props.isMobile &&
		props.details &&
		css`
			height: auto;
			padding: 15px 0;
		`}
`
