import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { color, Container } from './Global'
import { ReactComponent as VWLogo } from '../../assets/VW-Logo.svg'
import { ReactComponent as IconMushroom } from '../../assets/mushroom.svg'
import { useEffect, useState } from 'react'
import MobileMenu from './MobileMenu'
import { useStateTransition } from '../../customhooks'
import DelayedLink from './DelayedLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const navigation = [
	{ name: 'Home', path: '/' },
	{
		name: 'Products',
		path: '/products',
		submenu: [
			{ name: 'Beer', path: '/products/beer' },
			{ name: 'Mushroom', path: '/products/mushroom' },
		],
	},
	{ name: 'Our Story', path: '/our-story' },
	{ name: 'Contact', path: '/contact' },
	{ name: 'News', path: '/news' },
]

export default function NavBar({ isMobile, setIsMobile }) {
	const [vh100, setVh100] = useState(window.innerHeight)
	const [isScroll100vh, setIsScroll100vh] = useState(false)
	const [slideInMenu, setSlideInMenu] = useState(false)
	const [hover, setHover] = useState(false)
	const location = useLocation()

	const nav = navigation.map((navi) => {
		const isProductPages = location.pathname.includes('/products')
		return (
			<>
				{navi.submenu ? (
					<div
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
						onClick={(e) => e.preventDefault()}
						{...(isProductPages ? { className: 'active' } : {})}
						style={{ position: 'relative' }}
						key={`nav-${navi.name}`}
					>
						<span style={{ cursor: 'default' }}>{navi.name}</span>
						<DropDown
							submenu={navi.submenu}
							hover={hover}
							setHover={setHover}
							onMouseEnter={() => setHover(true)}
							onMouseLeave={() => setHover(false)}
							isActive={isProductPages}
						/>
					</div>
				) : (
					<DelayedLink
						key={`nav-${navi.name}`}
						to={navi.path}
						onClick={(e) => navi.path === '/products' && e.preventDefault()}
					>
						{({ isActive }) => (
							<li {...(isActive ? { className: 'active' } : {})}>
								{navi.name}
							</li>
						)}
					</DelayedLink>
				)}
			</>
		)
	})

	useEffect(() => {
		function resize() {
			setIsMobile(window.innerWidth < 800)
			setVh100(window.innerHeight)
		}

		function scroll() {
			if (window.scrollY > vh100) {
				setIsScroll100vh(true)
			} else {
				setIsScroll100vh(false)
			}
		}

		window.addEventListener('resize', resize)
		window.addEventListener('scroll', scroll)
		return () => {
			window.removeEventListener('resize', resize)
			window.removeEventListener('scroll', scroll)
		}
	}, [vh100, setIsMobile])

	const [mount, play] = useStateTransition(isScroll100vh)
	return (
		<>
			<Bar>
				<DelayedLink to={'/'}>
					<VWLogo />
				</DelayedLink>
				{isMobile ? (
					<span onClick={() => setSlideInMenu(true)} className="menu">
						Menu
					</span>
				) : (
					<ul className="ul">{nav}</ul>
				)}
			</Bar>
			{mount && !isMobile && (
				<FixedNav className={play ? 'mount' : 'unmount'}>
					<Container direction={'row'} spaceBetween>
						<DelayedLink to={'/'}>
							<VWLogo />
						</DelayedLink>
						<ul className="fixed-ul">{nav}</ul>
					</Container>
				</FixedNav>
			)}

			<MobileMenu
				mount={mount}
				play={play}
				slideInMenu={slideInMenu}
				setSlideInMenu={setSlideInMenu}
			/>

			{isMobile && <Toggle setSlideInMenu={setSlideInMenu} />}
		</>
	)
}

const DropDown = ({ submenu, hover, setHover }) => {
	return (
		<StyledDropdown
			hover={hover}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{submenu.map((menu, index) => (
				<DelayedLink key={menu + ' ' + index} to={menu.path}>
					<li>{menu.name}</li>
				</DelayedLink>
			))}
		</StyledDropdown>
	)
}
const ToggleMenu = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: ${color.secondary};
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 25px;
		height: auto;

		path {
			fill: ${color.white};
		}
	}
`
const Toggle = ({ setSlideInMenu }) => {
	const location = useLocation()
	const [icon, setIcon] = useState('')
	const [isMushroom, setIsMushroom] = useState(false)
	const setNewIcon = (path) => {
		setIcon(path)
		setIsMushroom(false)
	}

	useEffect(() => {
		if (location.pathname === '/') {
			setNewIcon('house')
		} else if (location.pathname === '/products/beer') {
			setNewIcon('beer-mug-empty')
		} else if (location.pathname === '/products/mushroom') {
			setIsMushroom(true)
		} else if (location.pathname === '/contact') {
			setNewIcon('address-book')
		} else if (location.pathname === '/news') {
			setNewIcon('newspaper')
		}
	}, [location.pathname])
	return (
		<ToggleMenu onClick={() => setSlideInMenu(true)}>
			{isMushroom ? (
				<IconMushroom />
			) : (
				<FontAwesomeIcon color={color.active} icon={icon} />
			)}
		</ToggleMenu>
	)
}

const Bar = styled.div`
	width: 60%;
	height: 60px;
	position: absolute;
	top: 10vh;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 5;

	.menu {
		color: ${color.white};
		height: auto;
		width: auto;
		user-select: none;
		font-size: 25px;
	}

	svg {
		width: 4vw;
		height: auto;
		path {
			transition: 0.35s ease-out;
			fill: ${color.active};
		}

		&:hover path {
			transition: 0.35s ease-out;
			fill: ${color.white};
		}
	}

	.ul {
		width: 45%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: auto;

		a {
			text-decoration: none;
		}

		li,
		div {
			font-size: 1.1vw;
			color: ${color.white};
			list-style: none;
			transition: 0.35s ease-out;

			&.active {
				position: relative;
				color: ${color.active};

				&::after {
					content: '';
					width: 150%;
					height: 125%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border: 2px solid ${color.active};
				}
			}

			&:hover {
				color: ${color.active};
			}
		}
	}

	@media screen and (max-width: 1023px) {
		width: 70%;

		svg {
			width: auto;
			height: 25px;
		}
	}
`

const FixedNav = styled.div`
	width: 100%;
	height: 7.5vh;
	position: fixed;
	top: 0;
	background-color: #ffffff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	user-select: none;
	z-index: 5;
	padding: 2vh;
	animation: slideOutUp 1s ease-out;
	opacity: 0;
	pointer-events: none;
	visibilty: hidden;
	transition: opacity 0.5s ease-out;

	svg {
		width: auto;
		height: 20px;
	}

	.fixed-ul {
		width: 45%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1vw;

		a,
		li {
			list-style: none;
			color: ${color.active};
		}

		li.active {
			color: ${color.black};
			position: relative;

			&::after {
				content: '';
				width: 110%;
				height: 2px;
				position: absolute;
				bottom: 0;
				left: 50%;
				background-color: ${color.black};
				transform: translateX(-50%);
			}
		}
	}

	&.mount {
		animation: slideInDown 1s ease-out;
		opacity: 0.95;
		pointer-events: visible;
		visibilty: visible;
		display: flex;
		transition: opacity 0.5s ease-out;
	}
`

const StyledDropdown = styled.ul`
	position: absolute;
	bottom: -100px;
	left: -10px;
	overflow: visible;
	background-color: ${color.black};
	padding: 15px;
	visibility: ${(props) => (props.hover ? 'visible' : 'hidden')};
	opacity: ${(props) => (props.hover ? '1' : '0')};
	transition: opacity 0.5s ease-out, visibility 0.35s;
	z-index: 500;

	li {
		color: ${color.white};
		margin-top: 5px;
		margin-bottom: 5px;

		&:hover {
			color: ${color.active};
		}
	}
`
