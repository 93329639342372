import styled from 'styled-components'
import { navigation } from './NavBar'
import { useLocation } from 'react-router-dom'
import { Col, color, VWRow } from './Global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import DelayedLink from './DelayedLink'
import { ReactComponent as VWLogo } from '../../assets/VW-Logo.svg'

export default function MobileMenu({ slideInMenu, setSlideInMenu }) {
	const location = useLocation()
	const modal = useRef()
	const [submenu, setSubmenu] = useState(false)

	useEffect(() => {
		const click = (e) => {
			if (e.target.tagName.toLowerCase() !== 'a') {
				console.log(e.target.tagName.toLowerCase())
				setSubmenu(false)
			}
		}

		if (modal.current) {
			const ref = modal.current
			ref.addEventListener('click', click)

			return () => ref.removeEventListener('click', click)
		}
	}, [])

	useEffect(() => {
		setSlideInMenu(false)
	}, [location.pathname, setSlideInMenu])
	return (
		<>
			<MobileNav slideInMenu={slideInMenu}>
				<VWLogo
					style={{
						position: 'absolute',
						top: '50px',
						left: '50px',
						width: 'auto',
						height: '20px',
					}}
				/>
				<Modal ref={modal}>
					<FontAwesomeIcon icon={'x'} onClick={() => setSlideInMenu(false)} />
					<Col>
						{navigation.map((nav) => {
							const isProductPages = location.pathname.includes('/products')
							return (
								<>
									{nav.path === '/products' ? (
										<li
											{...(isProductPages ? { className: 'active' } : {})}
											onClick={() => setSubmenu(true)}
											key={`nav-${nav.name}`}
										>
											{nav.name}
										</li>
									) : (
										<DelayedLink key={`nav-${nav.name}`} to={nav.path}>
											{({ isActive }) => (
												<li {...(isActive ? { className: 'active' } : {})}>
													{nav.name}
												</li>
											)}
										</DelayedLink>
									)}
								</>
							)
						})}
					</Col>
					{submenu && (
						<Col>
							<DelayedLink to={'/products/beer'}>
								{({ isActive }) => (
									<li {...(isActive ? { className: 'active' } : {})}>Beer</li>
								)}
							</DelayedLink>
							<DelayedLink to={'/products/mushroom'}>
								{({ isActive }) => (
									<li {...(isActive ? { className: 'active' } : {})}>
										Mushroom
									</li>
								)}
							</DelayedLink>
						</Col>
					)}
				</Modal>
				<VWRow logo={'10px'} padding={'0'} bottom>
					<span style={{ fontSize: '10px', marginBottom: '2.5px' }}>
						{new Date().getFullYear()} © VW Inter Trade Co., Ltd. ALL RIGHTS
						RESERVED.
					</span>
					<VWLogo className="logo" />
				</VWRow>
			</MobileNav>
		</>
	)
}

const Modal = styled.ul`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	pointer-events: visible;

	svg {
		color: ${color.white};
		position: absolute;
		top: 50px;
		right: 50px;
		width: 20px;
		height: auto;
		z-index: 1;
	}

	a {
		color: ${color.white};
		text-decoration: none;
	}

	li {
		list-style: none;
		margin: 15px 0;
		color: ${color.white};
		cursor: pointer;
		font-size: 25px;

		&.active {
			color: ${color.active};
		}
	}
`

const MobileNav = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	right: ${(props) => (props.slideInMenu ? '0' : '-100%')};
	z-index: 1000;
	background-color: ${color.black};
	opacity: 0.9;
	transition: 1s ease-out;
`
