import styled, { css } from 'styled-components'
import { useContext } from 'react'
import { Variable } from '../../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const color = {
	active: '#51BDE2',
	primary: '#082C38',
	secondary: '#156F87',
	dark: '#082D38',
	white: '#FFFFFF',
	gray: '#C0C0C0',
	black: '#000000',
}

export const SubText = styled.h6`
	font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '16px')};
	color: ${({ white }) => (white ? color.white : color.active)};
	margin: ${({ margin }) => (margin ? margin : '0')};
	${(props) =>
		props.color &&
		css`
			color: ${props.color};
		`}
	${({ textAlign }) =>
		textAlign &&
		css`
			text-align: ${textAlign};
		`}
	transition: 0.35s ease-out;

	@media screen and (max-width: 800px) {
		font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '13px')};
	}

	@media screen and (max-width: 600px) and (pointer: coarse) {
		font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '16px')};
	}
`

export const HeadText = styled.h5`
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '5vw')};
	color: ${(props) =>
		props.active ? color.active : props.color ? props.color : color.white};
	transition: 0.35s ease-out;

	${({ noBold }) =>
		noBold &&
		css`
			font-weight: normal;
		`}

	${({ margin }) =>
		margin &&
		css`
			margin: ${margin};
		`}

	${({ textAlign }) =>
		textAlign &&
		css`
			text-align: ${textAlign};
		`}

	@media screen and (max-width: 800px) {
		font-size: 40px;
	}

	@media screen and (max-width: 600px) and (pointer: coarse) {
		font-size: 12vw;
	}
`

export const Paragraph = styled.p`
	font-size: 1vw;
	color: ${(props) => (props.color ? props.color : color.white)};
	transition: 0.35s ease-out;

	${({ width }) =>
		width &&
		css`
			width: ${width};
		`}

	${({ align }) =>
		align &&
		css`
			text-align: ${align};
		`}

	${({ textAlign }) =>
		textAlign &&
		css`
			text-align: ${textAlign};
		`}

	${({ readmore }) =>
		readmore &&
		css`
			width: 50% !important;
			margin-${readmore === 'left' ? 'right' : 'left'}: auto;
			text-align: ${readmore};
		`}

	@media screen and (max-width: 1023px) {
		font-size: 18px;
	}

	@media screen and (max-width: 600px) and (pointer: coarse) {
		font-size: 16px;
		width: 90%;
		${({ news }) =>
			news &&
			css`
				margin: auto;
			`}
	}
`

export const Container = styled.div`
	position: relative;
	width: ${(props) => props.width || '60%'};
	height: ${(props) => props.height || '100%'};
	margin: auto;
	display: flex;
	flex-direction: ${(props) => props.direction || 'column'};
	align-items: center;
	${(props) =>
		props.spaceBetween &&
		css`
			justify-content: space-between;
		`}
	${(props) =>
		props.center &&
		css`
			justify-content: center;
		`}
		
	a {
		text-decoration: none;
		color: ${color.white};

		li {
			list-style: none;
		}
	}

	@media screen and (max-width: 1023px) {
		width: 70%;
	}

	@media screen and (max-width: 770px) {
		width: 75%;
	}

	@media screen and (max-width: 600px) and (pointer: coarse) {
		width: 90%;
	}
`

export const Section = styled.section`
	position: relative;
	width: 100%;
	height: auto;
	pointer-events: none;
	overflow: hidden;

	${(props) =>
		props.color &&
		css`
			background-color: ${props.color};
		`}

	${(props) =>
		props.padding &&
		css`
			padding: ${props.padding};
		`}
		
	${(props) =>
		props.overflow &&
		css`
			overflow: ${props.overflow};
		`}

	.swiper {
		z-index: 1;
		pointer-events: visiblefill;
	}
`

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${(props) => props.justify || 'center'};
	align-items: ${(props) => props.align || 'center'};
	pointer-events: visible;
	cursor: default;
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || '100%'};
	position: relative;
	overflow: hidden;
	${(props) =>
		!props.noPadding &&
		css`
			padding: 2vh 0;
		`}
	${(props) =>
		props.padding &&
		css`
			padding: ${props.padding};
		`}

	${(props) =>
		props.margin &&
		css`
			margin: ${props.margin};
		`}
		
	${(props) =>
		props.transform &&
		css`
			transform: ${props.transform};
		`}

	.text {
		font-size: 12px;
		margin-left: 10px;
	}

	.icon {
		width: 15px;
		height: auto;
		margin-right: 10px;
	}

	span {
		color: ${color.white};
		font-size: 12px;
		transition: 0.35s ease-out;

		&:hover {
			color: ${color.active};
		}
	}

	svg {
		fill: ${color.white};
	}

	div {
		color: ${color.white};
	}

	li {
		color: ${color.white};
		text-decoration: none;
		list-style: none;

		a {
			color: ${color.active};
		}
	}
`

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: ${(props) => props.justify || 'center'};
	align-items: ${(props) => props.align || 'center'};
	pointer-events: visible;
	width: ${(props) => props.width || '100%'};
	position: relative;
	overflow: hidden;
	cursor: default;
	${(props) =>
		!props.noPadding &&
		css`
			padding: 2vh 0;
		`}

	${(props) =>
		props.margin &&
		css`
			margin: ${props.margin};
		`}

	${(props) =>
		props.padding &&
		css`
			padding: ${props.padding};
		`}

	${(props) =>
		props.zIndex &&
		css`
			z-index: ${props.zIndex};
		`}
	
	${(props) =>
		props.height &&
		css`
			height: ${props.height};
		`}
		
	${(props) =>
		props.reverse &&
		css`
			flex-direction: column-reverse;
		`}

	${(props) =>
		props.overflow &&
		css`
			overflow: ${props.overflow};
		`}

	.text {
		font-size: 15px;
		margin-left: 10px;
	}

	.text-black {
		font-size: 15px;
		color: ${color.black};
	}

	.icon {
		width: 15px;
		height: auto;
		margin-right: 10px;
	}

	.logo {
		height: 11px;
		width: auto;
		margin-left: 5px;
		margin-bottom: 5px;

		path {
			transition: 0.35s ease-out;
			fill: ${color.white};
		}

		&:hover path {
			transition: 0.35s ease-out;
			fill: ${color.active};
		}
	}

	.coming-soon {
		font-size: 12px;
		color: ${color.active};
	}

	span {
		color: ${color.white};
		font-size: 12px;
		transition: 0.35s ease-out;

		&:hover {
			color: ${color.active};
		}
	}

	svg {
		fill: ${color.white};
	}

	div {
		color: ${color.white};
	}

	li {
		color: ${color.white};
		text-decoration: none;
		list-style: none;

		a {
			color: ${color.active};
		}
	}

	animation: ${(props) =>
		props.mount
			? `${props.animationIn} 1s ease-out 0.35s both`
			: `${props.animationOut} 1s ease-out both`};
`

export const ColTarget = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	transform: scale(1);
	transition: 0.5s ease-out;
	text-align: center;
	pointer-events: visible;
	width: 30%;
	${(props) =>
		!props.noPadding &&
		css`
			padding: 0 4vw;
		`}
	${(props) =>
		props.height &&
		css`
			height: ${props.height};
		`}

	&:hover {
		transform: scale(1.1);
	}

	h6 {
		font-size: 25px;
		color: ${color.active};
	}

	span {
		font-size: 15px;
		color: ${color.white};
	}

	img {
		width: 50px;
		height: auto;
	}

	svg {
		width: 40px;
		height: auto;
		margin-bottom: 15px;
	}

	@media screen and (max-width: 1023px) {
		img {
			width: 75px;
		}
	}

	@media screen and (max-width: 600px) {
		width: 100%;
	}
`

export const ResponsiveBox = ({ children, ...props }) => {
	const { isMobile } = useContext(Variable)
	return (
		<>
			{isMobile ? (
				<Col {...props}>{children}</Col>
			) : (
				<Row {...props}>{children}</Row>
			)}
		</>
	)
}

export const Filter = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.color || color.black};
	opacity: ${(props) => props.opacity || '0.2'};
	position: absolute;
	top: 0;
	left: 0;
	z-index: ${(props) => props.zIndex || '0'};
	pointer-events: none;
	transition: 1s ease-out;
`

export const List = styled.li`
	font-size: 20px;
	margin-top: 5px;

	@media screen and (max-width: 800px) {
		font-size: 18px;
	}

	@media screen and (max-width: 600px) and (pointer: coarse) {
		font-size: 16px;
	}
`

export const IntroBanner = styled.div`
	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
		`}
	${(props) =>
		props.position &&
		css`
			background-position: ${props.position};
		`}
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: ${(props) => (props.height ? props.height : '78vh')};
	position: relative;
`

export const OutroBanner = styled.div`
	width: 100%;
	height: 22vh;
	position: relative;
	background-color: ${color.black};
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;

	@media screen and (max-width: 600px) and (pointer: coarse) {
		height: calc(30vh - 75px);
	}
`

export const AdjustableBox = styled.div`
	width: auto;
	height: auto;
	position: absolute;
	z-index: ${(props) => props.zIndex || '2'};
	${(props) =>
		props.textAlign &&
		css`
			text-align: ${props.textAlign};
		`}
	${(props) =>
		props.top &&
		css`
			top: ${props.top};
		`}
		${(props) =>
		props.left &&
		css`
			left: ${props.left};
		`}
		${(props) =>
		props.right &&
		css`
			right: ${props.right};
		`}
		${(props) =>
		props.bottom &&
		css`
			bottom: ${props.bottom};
		`}
		${(props) =>
		props.translate &&
		css`
			transform: ${props.translate};
		`};
`

export const Banner = styled.img`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || '100vh'};
	object-fit: cover;
	object-position: ${(props) => props.position || 'center'};
	position: absolute;
	z-index: -1;

	@media screen and (max-width: 600px) and (pointer: coarse) {
		height: ${(props) =>
			`calc(${props.height} - 75px)` || 'calc(100vh - 75px)'};
	}
`

export const Center = styled.div`
	width: 100%;
	height: ${(props) => props.height || '100vh'};
	display: flex;
	flex-direction: ${(props) => props.direction || 'column'};
	justify-content: center;
	align-items: center;
	z-index: 3;

	p,
	span,
	h5,
	h6,
	h4,
	h3,
	h2,
	h1 {
		z-index: 1;
	}

	${(props) =>
		props.banner &&
		css`
			@media screen and (max-width: 600px) and (pointer: coarse) {
				height: ${(props) =>
					`calc(${props.height} - 75px)` || 'calc(100vh - 75px)'};
			}
		`}
`

export const Input = styled.input`
	font-family: 'Bebas Neue', cursive;
	font-size: 15px;
	color: ${color.active};
	border: 0;
	border-radius: 0;
	border-bottom: 3px solid ${color.active};
	padding: 5px 0;
	width: 100%;
	height: auto;
	margin: 5px 0;

	&:active,
	&:focus {
		outline: none;

		&::placeholder {
			color: transparent;
		}
	}

	&::placeholder {
		color: ${color.active};
	}
`

export const BreakLine = styled.div`
	width: 100%;
	height: 3px;
	background-color: ${color.secondary};
`

export const Bullet = styled.span`
	display: block;
	height: 100%;
	transition: 0.5s ease-out;
	background-color: ${(props) =>
		props.active && !props.brighter
			? '#00C2FF'
			: props.brighter && props.active
			? '#97DCF3'
			: '#226B84'};
	${(props) =>
		props.length &&
		css`
			width: calc(100vw / ${props.length});
		`}
`

export const BulletGroup = styled.div`
	height: 5px;
	width: 100%;
	position: absolute;
	bottom: 0;
	display: flex;
	z-index: 3;
`

export const Bullets = ({ length, activeIndex, brighter = false }) => {
	return (
		<BulletGroup>
			{[...Array(length)].map((_, index) => (
				<Bullet
					brighter={brighter}
					length={length}
					{...(activeIndex === index && { active: true })}
				/>
			))}
		</BulletGroup>
	)
}

const Arrow = styled.div`
	width: auto;
	height: auto;

	.chevron-left {
		position: absolute;
		${(props) =>
			props.position
				? css`
						${props.position.top && `top: ${props.position.top}`};
						${props.position.left && `left: ${props.position.left}`};
						${props.position.bottom && `bottom: ${props.position.bottom}`};
						${props.position.right && `right: ${props.position.right}`};
				  `
				: css`
						top: 50%;
						left: ${(props) => (props.banner ? '5%' : '-5%')};
				  `}

		transform: translate(-50%, -50%);
		z-index: 2;
		color: ${color.active};
		width: 20px;
		height: auto;
		pointer-events: visible;
		cursor: pointer;
	}

	.chevron-right {
		position: absolute;
		${(props) =>
			props.position
				? css`
						${props.position.top && `top: ${props.position.top}`};
						${props.position.left && `left: ${props.position.left}`};
						${props.position.bottom && `bottom: ${props.position.bottom}`};
						${props.position.right && `right: ${props.position.right}`};
				  `
				: css`
						top: 50%;
						right: ${(props) => (props.banner ? '5%' : '-5%')};
				  `}

		transform: translate(50%, -50%);
		z-index: 2;
		color: ${color.active};
		width: 20px;
		height: auto;
		pointer-events: visible;
		cursor: pointer;
	}
`

export const LeftArrow = ({ swiper, ...props }) => {
	return (
		<Arrow left {...props}>
			<FontAwesomeIcon
				className="chevron-left"
				icon={'chevron-left'}
				onClick={() => swiper.slidePrev()}
			/>
		</Arrow>
	)
}

export const RightArrow = ({ swiper, ...props }) => {
	return (
		<Arrow right {...props}>
			<FontAwesomeIcon
				className="chevron-right"
				icon={'chevron-right'}
				onClick={() => swiper.slideNext()}
			/>
		</Arrow>
	)
}

export const ExampleCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	h6 {
		margin-bottom: 10px;
	}

	h5 {
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 15px;
	}
`

export const VWRow = styled(Row)`
	${(props) =>
		props.bottom &&
		css`
			width: 90%;
			height: auto;
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
		`}

	.vw {
		font-size: 20px;
	}

	@media screen and (max-width: 600px) and (pointer: coarse) {
		.vw {
			font-size: 25px;
		}
	}

	.logo {
		height: ${(props) => props.logo || '16px'};
		width: auto;
		margin-left: 5px;
		margin-bottom: 5px;

		path {
			transition: 0.35s ease-out;
			fill: ${color.white};
		}

		&:hover path {
			transition: 0.35s ease-out;
			fill: ${color.active};
		}
	}
`
