import styled from 'styled-components'
import { ReactComponent as VWLogo } from '../assets/VW-Logo.svg'
import {
	Container,
	HeadText,
	SubText,
	Paragraph,
	Section,
	Row,
	ColTarget,
	Filter,
	Col,
	color,
	LeftArrow,
	RightArrow,
	Bullets,
	ExampleCol,
	VWRow,
} from './utilities/Global'

import Button from './utilities/Button'

import banner1 from '../assets/home/banner1.png'
import banner2 from '../assets/home/banner2.png'
import banner3 from '../assets/home/banner3.png'
import SevenEleven from '../assets/7-eleven.png'
import FoodLand from '../assets/foodland.png'
import MuanchonCafe from '../assets/muanchon-cafe.png'

import Prototype from '../assets/snowbeer.mp4'
import PrototypeHEVC from '../assets/snowbeer.hevc.mp4'

import Animation from './utilities/Animation'

import beer from '../assets/scene/beer.png'
import mushroom from '../assets/scene/enoki.png'
import Example from './utilities/Example'
import Sliders from './utilities/Sliders'
import { useState } from 'react'
import DelayedLink from './utilities/DelayedLink'
import { useContext } from 'react'
import { Variable } from '../App'

const Home = () => {
	const { isMobile, hovering } = useContext(Variable)
	const left = {
		src: beer,
		child: (
			<ExampleCol>
				<SubText>Products</SubText>
				<HeadText>Beer</HeadText>
				<Paragraph readmore={'right'}>
					Brewed with licorice; a proprietary, hand-smoked malt;
					<br />
					and almost a pound of East Kent Goldings hops per barrel.
					<br />
					<br />
					Opaque brown in color,
					<br />
					with muddy brown edges and a cola-colored head that drops quickly to a
					ringed lace. Strong and dominating licorice aroma with an underlying
					robust molasses-ness and highly roasted malts. Thick-ish, deep
					blackstrap molasses character (sweet, tangy nectar), quite robust.
				</Paragraph>
				<DelayedLink
					style={{ display: 'flex', justifyContent: 'end' }}
					to={'/products/beer'}
				>
					Read more
				</DelayedLink>
				<Filter
					color={color.black}
					opacity={hovering.left ? '35%' : '15%'}
					zIndex={'-1'}
				/>
			</ExampleCol>
		),
	}

	const right = {
		src: mushroom,
		child: (
			<ExampleCol>
				<SubText>Products</SubText>
				<HeadText>Mushroom</HeadText>
				<Paragraph readmore={'left'}>
					Native to Eastern Asia, Enoki mushrooms are tall, white velvet
					textured mushrooms with small caps. These mushrooms can be eaten raw
					or alternatively, cooked using methods such as boiling, blanching and
					simmering and so are great additions to variety of Asian hotpot
					cooking and recipes. Enoki mushrooms should be trimmed and washed
					before consumption.
					<br />
					<br />
					Fresh produce from Thailand.
				</Paragraph>
				<DelayedLink to={'/products/mushroom'}>Read more</DelayedLink>
				<Filter
					color={color.black}
					opacity={hovering.right ? '45%' : '30%'}
					zIndex={'-1'}
				/>
			</ExampleCol>
		),
	}

	const beerProducts = [
		{
			content: (
				<OurProducts playsInline loop muted autoPlay>
					<source src={Prototype} type={'video/mp4'} />
					<source src={PrototypeHEVC} type={'video/mp4; codecs=hvc1'} />
				</OurProducts>
			),
			key: 'Home-Video-1',
		},
		{
			content: (
				<OurProducts playsInline loop muted autoPlay>
					<source src={Prototype} type={'video/mp4'} />
					<source src={PrototypeHEVC} type={'video/mp4; codecs=hvc1'} />
				</OurProducts>
			),
			key: 'Home-Video-2',
		},
		{
			content: (
				<OurProducts playsInline loop muted autoPlay>
					<source src={Prototype} type={'video/mp4'} />
					<source src={PrototypeHEVC} type={'video/mp4; codecs=hvc1'} />
				</OurProducts>
			),
			key: 'Home-Video-3',
		},
	]

	const opts = { height: isMobile ? 'calc(85vh - 80px)' : '100vh' }
	const bannerTexts = [
		{
			content: (
				<Center {...opts}>
					<SubText>Our products</SubText>
					<HeadText>NO.1 BEER</HeadText>
					<HeadText>IN THE WORLD</HeadText>
					<Button to="/products/beer">Explore</Button>
					<Banner src={banner1} />
					<Filter color={color.black} opacity={'40%'} zIndex={'-1'} />
				</Center>
			),
			key: 'Home-Banner-1',
		},
		{
			content: (
				<Center {...opts}>
					<SubText>Our clean and fresh</SubText>
					<HeadText>MUSHROOM</HeadText>
					<Button to="/products/mushroom">Explore</Button>
					<Banner src={banner2} />
					<Filter color={color.black} opacity={'35%'} zIndex={'-1'} />
				</Center>
			),
			key: 'Home-Banner-2',
		},
		{
			content: (
				<Center {...opts}>
					<SubText>Welcome to</SubText>
					<HeadText>Our Factory</HeadText>
					<Button a="https://soronbiotechnology.net/">Visit our partner</Button>
					<Banner src={banner3} />
					<Filter color={color.black} opacity={'15%'} zIndex={'-1'} />
				</Center>
			),
			key: 'Home-Banner-3',
		},
	]

	const [swiper, setSwiper] = useState(null)
	const [, setProductSwiper] = useState(null)
	const [activeSlide, setActiveSlide] = useState(0)
	const [productActiveSlide, setProductActiveSlide] = useState(0)

	document.title = 'VW Inter Trade'
	return (
		<>
			{/* Banner */}
			<Section noPadding>
				<LeftArrow swiper={swiper} banner />
				<Sliders
					setSwiper={setSwiper}
					setActiveSlide={setActiveSlide}
					slidesPerView={1}
					banner
					autoplay
					pagination
				>
					{bannerTexts}
				</Sliders>
				<RightArrow swiper={swiper} banner />
				<Bullets length={bannerTexts.length} activeIndex={activeSlide} />
			</Section>

			{/* About Us */}
			<Section color={color.primary} padding={'5vh 0'}>
				<Container>
					<Center>
						<SubText>Our information</SubText>
						<HeadText>About Us</HeadText>
						<VWRow padding={'10px 0 7.5px 0'}>
							<span className="vw">VW Inter Trade Co., Ltd.</span>
							<VWLogo className="logo" />
						</VWRow>
						<Paragraph align={'center'}>
							operates as a distributor of products. Which takes into account
							the services that are in line with the needs of the partner
							companies. Providing comprehensive market expansion services by
							focusing on selecting quality and outstanding products from both
							inside and outside the country to meet the needs of customers.
							<br />
							<br /> Whether it is Modern trade customers, Hypermarket, as well
							as other groups of customers. It also aims to provide market
							expansion services for companies that want to expand their
							business in new markets and want to grow in existing markets.
						</Paragraph>
					</Center>

					<Animation
						animateIn="animate__backInUp"
						animateOut="animate__fadeOutDown"
						duration={0.5}
						offset={30}
					>
						<Center>
							<SubText margin={'5vh 0 0 0'}>All branches nationwide</SubText>
							<Row width={'100%'}>
								<ColTarget
									noPadding
									as={'a'}
									href={'https://www.7eleven.co.th/find-store'}
								>
									<img src={SevenEleven} alt="7-Eleven" />
									<SubText fontSize={'15px !important'} margin={'2vh 0'}>
										7-Eleven
									</SubText>
								</ColTarget>
								<ColTarget
									as={'a'}
									href={'https://www.foodland.co.th/th-th/reader-64-page.html'}
								>
									<img src={FoodLand} alt="Foodland" />
									<SubText fontSize={'15px !important'} margin={'2vh 0'}>
										Foodland
									</SubText>
								</ColTarget>
								<ColTarget
									noPadding
									as={'a'}
									href={'https://www.facebook.com/muanchoncafe/'}
								>
									<img src={MuanchonCafe} alt="Muanchon Cafe" />
									<SubText fontSize={'15px !important'} margin={'2vh 0'}>
										Muanchon Cafe
									</SubText>
								</ColTarget>
							</Row>
							<Button to={'/our-story'}>Show Me More</Button>
						</Center>
					</Animation>
				</Container>
			</Section>

			{/* Our Products */}
			<Section noPadding>
				<Animation
					animateIn="animate__fadeInUp"
					animateOut="animate__fadeOutDown"
					offset={0}
				>
					<Container>
						<Left>
							<Col align={'baseline'}>
								<SubText fontSize={'30px'}>Browse</SubText>
								<HeadText>Our Products</HeadText>
								<br />
								<HeadText color="#92D9F1">Snow Beer</HeadText>
								<SubText fontSize={'30px'} color="#92D9F1">
									580 Ml.
								</SubText>
								<br />
								<Button to={'/products/beer'}>Learn More</Button>
							</Col>
						</Left>
					</Container>
					<Sliders
						setSwiper={setProductSwiper}
						setActiveSlide={setProductActiveSlide}
						slidesPerView={1}
						banner
						height={'75vh'}
						autoplay
						pagination
					>
						{beerProducts}
					</Sliders>
					<Bullets
						length={beerProducts.length}
						activeIndex={productActiveSlide}
					/>
				</Animation>
			</Section>

			{/* Read More */}
			<Example left={left} right={right} positionRight={'83%'} />
		</>
	)
}

const Center = styled.div`
	width: 100%;
	height: ${(props) => props.height || 'auto'};
	display: flex;
	flex-direction: ${(props) => props.direction || 'column'};
	justify-content: center;
	align-items: center;
	z-index: 3;
`

const Left = styled.div`
	height: ${(props) => props.height || 'auto'};
	position: absolute;
	left: 0;
	top: 5vh;
	z-index: 3;
`

const Banner = styled.img`
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	position: absolute;
	z-index: -1;
`

const OurProducts = styled.video`
	width: 130%;
	height: 75vh;
	padding: 0;
	margin: 0;
	object-fit: cover;

	@media screen and (max-width: 1023px) {
		width: 160%;
		height: 75vh;
		object-position: top;
	}
`
export default Home
