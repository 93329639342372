import { Outlet } from 'react-router-dom'
import NavBar from './utilities/NavBar'
import Footer from './utilities/Footer'
import { useContext } from 'react'
import { Variable } from '../App'

const Layout = ({ setIsMobile }) => {
	const { isMobile } = useContext(Variable)
	return (
		<>
			<NavBar isMobile={isMobile} setIsMobile={setIsMobile} />
			<Outlet />
			<Footer />
		</>
	)
}

export default Layout
