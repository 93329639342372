import { useState } from 'react'
import styled, { css } from 'styled-components'

export default function ImageTarget({ src, alt, size, href = '' }) {
	const [hover, setHover] = useState(false)
	return (
		<Img
			src={src}
			alt={alt}
			sizes={size}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			{...(href && { onClick: () => (window.location.href = href) })}
			{...(hover ? { className: 'zoom' } : {})}
		/>
	)
}

const Img = styled.img`
	${(props) =>
		props.sizes &&
		css`
			width: ${props.sizes};
			height: auto;
		`}
	margin-bottom: 1vh;
	transform: scale(1);
	transition: 0.35s ease-out;

	&.zoom {
		transform: scale(1.1);
		transition: 0.35s ease-out;
	}
`
