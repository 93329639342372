import {
	color,
	Container,
	Filter,
	IntroBanner,
	Row,
	Col,
	SubText,
	HeadText,
	Section,
	AdjustableBox,
	OutroBanner,
	Paragraph,
	ResponsiveBox,
	BreakLine,
	LeftArrow,
	RightArrow,
	Bullets,
	ExampleCol,
	ColTarget,
} from '../utilities/Global'
import styled from 'styled-components'
import Banner from '../../assets/mushroom/Banner.png'
import SevenEleven from '../../assets/7-eleven.png'
import FoodLand from '../../assets/foodland.png'
import MuanchonCafe from '../../assets/muanchon-cafe.png'

import Product1 from '../../assets/mushroom/enoki.png'
import Product2 from '../../assets/mushroom/shimeji.png'
import Product3 from '../../assets/mushroom/brown-beech.png'
import Product4 from '../../assets/mushroom/white-beech.png'
import Product5 from '../../assets/mushroom/double-flavor.png'
import Product6 from '../../assets/mushroom/king-oyster-200.png'
import Product7 from '../../assets/mushroom/king-oyster-1000.png'

import Example from '../utilities/Example'
import leftImg from '../../assets/mushroom/example-left.png'
import rightImg from '../../assets/mushroom/example-right.png'
import { useContext, useState } from 'react'
import { Variable } from '../../App'
import ImageTarget from '../utilities/ImageTarget'
import Sliders from '../utilities/Sliders'
import Animation from '../utilities/Animation'

const Mushroom = () => {
	const { isMobile, hovering } = useContext(Variable)
	const left = {
		src: leftImg,
		child: (
			<ExampleCol>
				<SubText>Mushroom</SubText>
				<HeadText textAlign={'right'}>Enoki Mushroom</HeadText>
				<Paragraph readmore={'right'}>
					Golden Enoki contains a range of vitamins and minerals including
					vitamin B6, magnesium, calcium, iron and dietary fiber.
					<br />
					<br />
					They also have anti-inflammatory properties and help boost the immune
					system.
				</Paragraph>
				<Filter
					color={color.black}
					opacity={hovering.left ? '60%' : '35%'}
					zIndex={'-1'}
				/>
			</ExampleCol>
		),
	}

	const right = {
		src: rightImg,
		child: (
			<ExampleCol>
				<SubText>Mushroom</SubText>
				<HeadText textAlign={'left'}>Manufacturing Plant</HeadText>
				<Paragraph readmore={'left'}>
					Manufactured with innovation and modern technology. To ensure that our
					products are safe from contaminants with GAP, GMP and HALAL (Halal)
					standards because every product that leaves our production facility.
					<br />
					<br />
					is the intention to provide the best to consumers
				</Paragraph>
				<a href={'https://soronbiotechnology.net/'}>Visit our Factory</a>
				<Filter
					color={color.black}
					opacity={hovering.right ? '60%' : '45%'}
					zIndex={'-1'}
				/>
			</ExampleCol>
		),
	}

	const partners = [
		{
			name: '7-Eleven',
			src: SevenEleven,
			key: 'partner-1',
			href: 'https://www.7eleven.co.th/find-store',
		},
		{
			name: 'Foodland',
			src: FoodLand,
			key: 'partner-2',
			href: 'https://www.foodland.co.th/th-th/reader-64-page.html',
		},
		{
			name: `Muanchon`,
			src: MuanchonCafe,
			key: 'partner-3',
			href: 'https://www.facebook.com/muanchoncafe/',
		},
	]

	const datas = [
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={Product1} alt={''} />
					<span className="coming-soon" style={{ opacity: 0 }}>
						Coming soon
					</span>
					<span className="text-black">Enoki Mushroom</span>
					<span className="text-active">220 G</span>
				</Col>
			),
			key: 'Mushroom-Product-1',
		},
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={Product2} alt={''} />
					<span className="coming-soon">Coming soon</span>
					<span className="text-black">Snow Shimeji</span>
					<span className="text-active">150 G</span>
				</Col>
			),
			key: 'Mushroom-Product-2',
		},
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={Product3} alt={''} />
					<span className="coming-soon">Coming soon</span>
					<span className="text-black">Brown Beech</span>
					<span className="text-active">175 G</span>
				</Col>
			),
			key: 'Mushroom-Product-3',
		},
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={Product4} alt={''} />
					<span className="coming-soon">Coming soon</span>
					<span className="text-black">White Beech</span>
					<span className="text-active">175 G</span>
				</Col>
			),
			key: 'Mushroom-Product-4',
		},
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={Product5} alt={''} />
					<span className="coming-soon">Coming soon</span>
					<span className="text-black">Double Flavor</span>
					<span className="text-active">350 G</span>
				</Col>
			),
			key: 'Mushroom-Product-5',
		},
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={Product6} alt={''} />
					<span className="coming-soon">Coming soon</span>
					<span className="text-black">King Oyster</span>
					<span className="text-active">200 G</span>
				</Col>
			),
			key: 'Mushroom-Product-6',
		},
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={Product7} alt={''} />
					<span className="coming-soon">Coming soon</span>
					<span className="text-black">King Oyster</span>
					<span className="text-active">1000 G</span>
				</Col>
			),
			key: 'Mushroom-Product-7',
		},
	]

	const [swiper, setSwiper] = useState({})
	const [activeSlide, setActiveSlide] = useState(0)

	document.title = 'Mushroom - VW Inter Trade'
	return (
		<>
			{/* Our Mushroom */}
			<Section overflow={'hidden'}>
				<IntroBanner src={Banner}>
					<Container height={'70vh'}>
						<AdjustableBox
							left={'50%'}
							top={'60%'}
							translate={'translate(-50%, -50%)'}
							textAlign={'center'}
						>
							<SubText>Find out more about</SubText>
							<HeadText>Mushroom</HeadText>
						</AdjustableBox>
					</Container>
					<Filter opacity={'50%'} zIndex={'1'} color={color.black} />
				</IntroBanner>
				<OutroBanner>
					<Container {...(isMobile && { width: '95% !important' })}>
						<Row align={'center'}>
							{partners.map((partner) => (
								<ColTarget
									as={'a'}
									href={partner.href}
									target={'_blank'}
									key={partner.key}
									{...(!isMobile && { width: '150px' })}
								>
									<ImageTarget size={'50px'} src={partner.src} alt="" />
									<h6>{partner.name}</h6>
									<span>
										All branches
										<br />
										nationwide
									</span>
								</ColTarget>
							))}
						</Row>
					</Container>
				</OutroBanner>
			</Section>

			{/* About Us */}
			<Section color={'#FDFDFF'}>
				<Animation animateIn={'animate__fadeInDown'}>
					<Container>
						<ResponsiveBox>
							<AboutUs isMobile={isMobile} />
						</ResponsiveBox>
					</Container>
				</Animation>
			</Section>

			<BreakLine />

			{/* Our Stock */}
			<Section color={'#FDFDFF'}>
				<Animation animateIn={'animate__fadeInUp'}>
					<Container>
						<LeftArrow swiper={swiper} />
						<ResponsiveBox>
							<Col
								width={isMobile ? '100%' : '50%'}
								align={isMobile ? 'center' : 'baseline'}
							>
								<SubText>Check out more of</SubText>
								<HeadText color={color.black}>Our Stock</HeadText>
								<Quote>"clean, safe, confident"</Quote>
								<Paragraph
									color={color.black}
									{...(isMobile && { textAlign: 'center' })}
								>
									Can be used in all types of food that you can create. Whether,
									put in a shabu pot in the evening with your family, or even go
									to bake or grill as well
								</Paragraph>
							</Col>
							<Sliders
								setSwiper={setSwiper}
								setActiveSlide={setActiveSlide}
								slidesPerView={isMobile ? 1 : 3}
								autoplay
								pagination
								className={'products'}
							>
								{datas}
							</Sliders>
						</ResponsiveBox>
						<RightArrow swiper={swiper} />
					</Container>
				</Animation>
				<Bullets brighter length={datas.length} activeIndex={activeSlide} />
			</Section>

			{/* Examples */}
			<Example fontSize={'2vw'} left={left} right={right} />
		</>
	)
}

function AboutUs({ isMobile }) {
	return (
		<>
			{isMobile && (
				<Col>
					<SubText>Thailand</SubText>
					<HeadText color={color.black} textAlign={'center'}>
						ENOKI MUSHROOM
					</HeadText>
				</Col>
			)}
			<ImageTarget src={Product1} alt={''} size={isMobile ? '80%' : '40%'} />
			<Col
				padding={isMobile ? '20px 0 0 0' : '0 0 0 50px'}
				align={isMobile ? 'center' : 'baseline'}
			>
				{!isMobile && (
					<>
						<SubText>Thailand</SubText>
						<HeadText color={color.black} textAlign={'center'}>
							ENOKI MUSHROOM
						</HeadText>
					</>
				)}
				<Quote>“Where clean technology meets freshness”</Quote>
				<Paragraph
					color={color.black}
					{...(isMobile && { textAlign: 'center' })}
				>
					Mushrooms that have been natural Cultivated and harvest in fresh and
					processed from quality factories. no any dangerous chemicals includes.
					<br />
					<br />
					We can Ensured cleanliness and quality for you to enjoy our products.
				</Paragraph>
			</Col>
		</>
	)
}

const Quote = styled.p`
	color: ${color.active};
	font-size: 25px;
	margin: 5px 0 20px 0;

	@media screen and (max-width: 800px) {
		font-size: 15px;
		text-align: center;
	}
`

export default Mushroom
