import styled from 'styled-components'
import DelayedLink from './DelayedLink'
import { color } from './Global'

const Button = ({
	children,
	to = '',
	a = '',
	inverse = false,
	submit,
	...props
}) => {
	return (
		<>
			{submit ? (
				<StyledButton inverse={inverse} {...props}>
					<Main>{children}</Main>
				</StyledButton>
			) : (
				<StyledButton inverse={inverse}>
					{a ? (
						<a href={a} {...props}>
							<Main>{children}</Main>
						</a>
					) : (
						<DelayedLink to={to}>
							<Main inverse={inverse}>{children}</Main>
						</DelayedLink>
					)}
				</StyledButton>
			)}
		</>
	)
}

export const StyledButton = styled.div`
	width: 15vw;
	height: 7vh;
	border: 1px solid ${(props) => (props.inverse ? color.black : color.white)};
	position: relative;
	padding: 3px;
	margin-top: 10px;
	transition: 0.5s ease-out;

	a {
		text-decoration: none;
	}

	&:hover {
		border: 1px solid ${color.active};

		button {
			color: ${color.active};
			background-color: ${color.white};
			border: 1px solid ${color.active};
		}
	}

	@media screen and (max-width: 1023px) {
		width: 250px;
	}

	@media screen and (max-width: 800px) {
		width: 200px;
	}

	@media screen and (max-width: 600px) {
		width: 200px;
	}
`

export const Main = styled.button`
	font-family: 'Bebas Neue', cursive;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${color.white};
	background-color: ${color.active};
	border: 1px solid ${(props) => (props.inverse ? color.black : color.white)};
	font-size: 2vw;
	transition: 0.5s ease-out;
	user-select: none;
	cursor: pointer;
	pointer-events: visible;

	@media screen and (max-width: 1023px) {
		font-size: 25px;
	}

	@media screen and (max-width: 800px) {
		font-size: 20px;
	}

	@media screen and (max-width: 600px) {
		font-size: 20px;
	}
`

export default Button
