import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper'
// import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { color } from './Global'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import React, { useEffect, useState } from 'react'

export default function Sliders({
	setSwiper,
	setActiveSlide,
	autoplay,
	pagination,
	slidesPerView,
	children,
	banner = false,
	delay = 4500,
	height,
	...props
}) {
	const params = {
		spaceBetween: 30,
		centeredSlides: true,
		...(slidesPerView && { slidesPerView: slidesPerView }),
		...(autoplay && {
			autoplay: {
				delay: delay,
				disableOnInteraction: false,
			},
		}),
		// ...(pagination && {
		// 	pagination: {
		// 		el: '.swiper-pagination',
		// 		clickable: true,
		// 		renderBullet: (index, className) => {
		// 			return <Bullets length={children.length} />
		// 		},
		// 	},
		// }),
		onSwiper: (swiper) => setSwiper(swiper),
		onSlideChange: (slide) => setActiveSlide(slide.realIndex),
		modules: [Autoplay, Pagination, Navigation],
		loop: true,
		...props,
	}

	return (
		<SwiperWrapper banner={banner} {...(height && { height: height })}>
			<Swiper {...params}>
				{children.map((child) => (
					<SwiperSlide key={child.key}>{child.content}</SwiperSlide>
				))}
			</Swiper>
		</SwiperWrapper>
	)
}

const SwiperWrapper = styled.div`
	width: ${(props) => (props.banner ? '100%' : '50%')};
	height: ${(props) => props.height || '100%'};
	user-select: none;
	overflow: visible;

	${(props) =>
		!props.banner &&
		css`
			.swiper-slide {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		`}
	${(props) =>
		!props.banner &&
		!props.isMobile &&
		css`
			margin-left: 20px;
			margin-right: 20px;
		`}

		.swiper-pagination {
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	.products {
		width: 100%;
		height: 60vh;
		text-align: center;

		.text {
			font-size: 15px;
			color: ${color.white};
			margin: 12px auto 3px auto;
		}

		.text-active {
			font-size: 20px;
			color: ${color.active};
			margin: auto;
		}
	}
`
