import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useStateTransition = (condition) => {
	const [mount, setMount] = useState(false)
	const [play, setPlay] = useState(false)

	useEffect(() => {
		if (condition) {
			setMount(true)
		} else {
			setTimeout(() => setMount(false), 500)
			setPlay(false)
		}
	}, [condition])

	useEffect(() => {
		if (mount) {
			setTimeout(() => setPlay(true), 100)
		}
	}, [mount])

	return [mount, play]
}

export const useIsPortrait = () => {
	const [isPortrait, setIsPortrait] = useState(null)
	useEffect(() => {
		const media = window.matchMedia('(orientation: landscape)')
		const change = (media) => {
			if (media.matches) {
				setIsPortrait(false)
			} else {
				setIsPortrait(true)
			}
		}

		if (media.matches) {
			setIsPortrait(false)
		} else {
			setIsPortrait(true)
		}

		media.addEventListener('change', change)

		return () => {
			media.removeEventListener('change', change)
		}
	}, [])

	return isPortrait
}

export const useEmail = (endpointUrl) => {
	const [submitted, setSubmitted] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState()

	const sendEmail = (data) => {
		setLoading(true)
		setSubmitted(false)
		setError(undefined)

		fetch(endpointUrl, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then((response) => {
				// Endpoint thinks that it's likely a spam/bot request, you need to change "spam protection mode" to "never" in HeroTofu forms
				if (response.status === 422) {
					throw new Error('Are you robot?')
				}

				if (response.status !== 200) {
					throw new Error(`${response.statusText} (${response.status})`)
				}

				return response.json()
			})
			.then(() => {
				setSubmitted(true)
				setLoading(false)
			})
			.catch((err) => {
				setError(err.toString())
				setLoading(false)
			})
	}

	const timeoutRef = useRef({})
	useEffect(() => {
		const timeout = timeoutRef.current
		if (submitted) {
			timeout.submitted = setTimeout(() => setSubmitted(false), 3000)
		}

		if (error) {
			timeout.error = setTimeout(() => setError(false), 3000)
		}

		if (loading) {
			timeout.loading = setTimeout(() => setLoading(false), 3000)
		}

		return () => {
			clearTimeout(timeout.submitted)
			clearTimeout(timeout.error)
			clearTimeout(timeout.loading)
		}
	}, [submitted, error, loading])

	return {
		submitted,
		loading,
		error,
		sendEmail,
	}
}

export const useIsClickNewPage = () => {
	const [pageChange, setPageChange] = useState(null)

	useEffect(() => {
		if (pageChange) {
			setTimeout(() => setPageChange(false), 3500)
		}
	}, [pageChange])

	return [pageChange, setPageChange]
}
