import { Route, Routes, useLocation } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './components/Home'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import {
	faYoutube,
	faFacebook,
	faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import Beer from './components/Products/Beer'
import OurStory from './components/OurStory'
import Contact from './components/Contact'
import News from './components/News'
import 'animate.css'
import Mushroom from './components/Products/Mushroom'
import { useState } from 'react'
import { createContext } from 'react'
import { useEffect } from 'react'
import Loading from './components/utilities/Loading'

const iconList = Object.keys(Icons)
	.filter((key) => key !== 'fas' && key !== 'prefix')
	.map((icon) => Icons[icon])

library.add(...iconList, faYoutube, faFacebook, faInstagram)

export const Variable = createContext(null)

function App() {
	const location = useLocation()
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1023)
	const [hovering, setHovering] = useState({ left: null, right: null })
	const [isClickNewPage, setIsClickNewPage] = useState(false)
	const value = {
		isMobile,
		isClickNewPage,
		setIsClickNewPage,
		hovering,
		setHovering,
	}

	useEffect(() => {
		setTimeout(() => setIsClickNewPage(false), 500)
	}, [location.pathname])

	useEffect(() => {
		console.log(isClickNewPage)
	}, [isClickNewPage])

	return (
		<Variable.Provider value={value}>
			<Loading />
			<Routes>
				<Route path="/" element={<Layout setIsMobile={setIsMobile} />}>
					<Route index element={<Home />} />
					<Route path="/products/beer" element={<Beer />} />
					<Route path="/products/mushroom" element={<Mushroom />} />
					<Route path="/our-story" element={<OurStory />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/news" element={<News />} />
				</Route>
			</Routes>
		</Variable.Provider>
	)
}

export default App
