import {
	IntroBanner,
	Center,
	SubText,
	HeadText,
	Section,
	Container,
	Col,
	ColTarget,
	Paragraph,
	Row,
	color,
	ResponsiveBox,
	Filter,
} from './utilities/Global'
import banner from '../assets/news/banner.png'
import news1 from '../assets/news/news-1.png'
import news2 from '../assets/news/news-2.png'
import news3 from '../assets/news/news-3.png'
import styled, { css } from 'styled-components'
import ImageTarget from './utilities/ImageTarget'
import { useContext } from 'react'
import { Variable } from '../App'

const News = () => {
	const { isMobile } = useContext(Variable)
	const news = [
		<Blog src={news1}>
			<HeadText textAlign={isMobile ? 'center' : 'left'} active>
				7 Unique Benefits of Enoki Mushrooms
			</HeadText>
			<Paragraph>
				Enoki mushrooms are a versatile, tasty fungus known for their crisp
				texture and mild flavor.
			</Paragraph>
			<br />
			<Paragraph>
				Also known as winter mushrooms or golden needle mushrooms, enoki
				mushrooms are featured in many types of cuisine and commonly cultivated
				in regions throughout North America, Europe, and Asia (1Trusted Source).
				Here are 7 of the top benefits of enoki mushrooms.....
			</Paragraph>

			<a
				href="https://www.healthline.com/nutrition/enoki-mushrooms-benefits"
				target={'_blank'}
				rel={'noreferrer'}
			>
				Read more
			</a>
		</Blog>,
		<Blog src={news2}>
			<HeadText active>How is Beer made?</HeadText>
			<Paragraph>
				Many think brewing beer is complicated, but the reality is that it’s a
				very natural process. Most of the beer we all drink is made from the
				same four ingredients. Barley, water, hops and yeast, yes that’s all it
				takes to create the world’s most refreshing drink. The basic idea is to
				extract the sugars from grains (usually barley) so that the yeast can
				turn it into alcohol and CO2, creating beer.....
			</Paragraph>
			<a
				href="https://www.matthewclark.co.uk/latest-news-blog/blog/how-is-beer-made/"
				target={'_blank'}
				rel={'noreferrer'}
			>
				Read more
			</a>
		</Blog>,
		<Blog src={news3} href="https://www.whatisadesignaward.com/">
			<HeadText active>Award Design</HeadText>
			<Paragraph>
				A' Design Award and Competition is one of the World’s largest, most
				prestigious and influential design accolades, one of the highest
				achievements in design. A' Design Award Winner Logo, symbolizes
				exceptional design excellence in your products, projects and
				services.....
			</Paragraph>
			<a
				href="https://www.whatisadesignaward.com/"
				target={'_blank'}
				rel={'noreferrer'}
			>
				Read more
			</a>
		</Blog>,
	]

	document.title = 'News - VW Inter Trade'
	return (
		<>
			<Section>
				<IntroBanner
					height={isMobile ? 'calc(90vh - 90px)' : '83vh'}
					src={banner}
					position={'87.5%'}
				>
					<Center height={'100%'}>
						<SubText>We delivers</SubText>
						<HeadText>News</HeadText>
						<Filter />
					</Center>
				</IntroBanner>
			</Section>

			<Section color={color.primary}>
				<Container>
					<Col align={isMobile ? 'center' : 'baseline'}>
						<SubText>Our fresh</SubText>
						<HeadText>Hot News</HeadText>
						{news.map((_news) => (
							<>{_news}</>
						))}
					</Col>
				</Container>
			</Section>
		</>
	)
}

const Topic = styled(ResponsiveBox)`
	border: 1px solid ${color.active};
	padding: 30px;
	overflow: visible;

	img {
		margin-right: 25px;
	}

	h5 {
		font-size: 30px;
		margin-bottom: 10px;
	}

	a {
		position: absolute;
		bottom: -15px;
		right: 10px;
		color: ${color.active};
		font-size: 20px;
	}

	p {
		text-indent: 10px;
	}

	@media screen and (max-width: 1023px) {
		img {
			margin-right: 0;
		}
	}
`

const Blog = ({ src, children, href, ...props }) => {
	const { isMobile } = useContext(Variable)
	return (
		<Topic {...props}>
			<ImageTarget src={src} alt="" size={'auto'} href={href} />
			<Col align={isMobile ? 'center' : 'flex-start'} overflow={'visible'}>
				{children}
			</Col>
		</Topic>
	)
}

export default News
