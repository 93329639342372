import { AnimationOnScroll } from 'react-animation-on-scroll'

export default function Animation({ children, ...props }) {
	return (
		<AnimationOnScroll
			style={{ width: '100%', height: '100%' }}
			animateOnce
			{...props}
		>
			{children}
		</AnimationOnScroll>
	)
}
