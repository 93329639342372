import {
	Col,
	color,
	Container,
	HeadText,
	Paragraph,
	Banner,
	Row,
	Section,
	SubText,
	Center,
	Filter,
} from './utilities/Global'
import styled from 'styled-components'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import banner from '../assets/ourstory/banner.png'
import { useContext } from 'react'
import { Variable } from '../App'

export default function OurStory() {
	const { isMobile } = useContext(Variable)
	const [height, setHeight] = useState(0)
	const line = useRef()
	const [bg, setBg] = useState(null)
	const [resizing, setResizing] = useState(0)
	const backgroundRef = useRef(null)
	const points = useRef([])

	useEffect(() => {
		const resize = () => {
			setResizing(window.innerWidth)
		}
		window.addEventListener('resize', resize)

		return () => window.removeEventListener('resize', resize)
	}, [])

	useEffect(() => {
		if (backgroundRef && backgroundRef.current) {
			const background = backgroundRef.current.getBoundingClientRect()
			setBg(background.height)
		}
	}, [backgroundRef, resizing])

	useEffect(() => {
		const target = line.current
		const scroll = () => {
			const y = document.documentElement.scrollTop || document.body.scrollTop
			const targetRect = target.getBoundingClientRect()
			const dy = y * (2 / 3) - targetRect.top
			if (dy > 0) {
				setHeight(dy)
			} else {
				setHeight(0)
			}
		}
		window.addEventListener('scroll', scroll)

		return () => window.removeEventListener('scroll', scroll)
	}, [])

	const [triggeredPoint, setTriggeredPoint] = useState({})

	useEffect(() => {
		const thisBackground = backgroundRef.current
		const scroll = () => {
			const background = thisBackground.getBoundingClientRect()
			points.current.forEach((thisPoint, index) => {
				const point = thisPoint.getBoundingClientRect()
				const dPoint = background.top - point.top
				if (height + 20 >= Math.abs(dPoint)) {
					setTriggeredPoint((prev) => ({ ...prev, [index]: true }))
				} else {
					setTriggeredPoint((prev) => ({ ...prev, [index]: false }))
				}
			})
		}

		window.addEventListener('scroll', scroll)

		return () => window.removeEventListener('scroll', scroll)
	}, [height])

	const [mount, setMount] = useState({})

	useEffect(() => {
		points.current.forEach((point, index) => {
			if (triggeredPoint[index]) {
				point.style.opacity = 1
				point.style.transform = 'translate(-50%, 150%) scale(1)'
				setMount((prev) => ({ ...prev, [index]: true }))
			} else {
				point.style.opacity = 0
				point.style.transform = 'translate(-50%, 150%) scale(0)'
				setMount((prev) => ({ ...prev, [index]: false }))
			}
		})
	}, [triggeredPoint])

	const leftPadding = '0 15px 0 0'
	const rightPadding = '0 0 0 15px'
	const _1993 = (
		<Col zIndex={'3'}>
			<DateText mount>1993</DateText>

			<Row align={'baseline'}>
				<Col
					margin={leftPadding}
					align={'flex-end'}
					mount={mount[0]}
					animationIn={'fadeInLeft'}
					animationOut={'fadeOutLeft'}
				>
					<SubText textAlign={'right'}>January</SubText>
					<Paragraph textAlign={'right'}>
						SNOW was first put on the market in 1993. SNOW Originated from
						Shenyang Province northeastern provinces of china
					</Paragraph>
				</Col>

				<Point ref={(ref) => (points.current[0] = ref)} />

				<Col
					margin={rightPadding}
					align={'flex-start'}
					mount={mount[0]}
					animationIn={'fadeInRight'}
					animationOut={'fadeOutRight'}
				>
					<SubText>January</SubText>
					<Paragraph textAlign={'left'}>
						SNOW BEER It is a premium beer of the group and is popular among the
						younger generation. Snow Beer Starting with the "Snow Great
						Expedition" and "Snow Brave the World" campaigns in 2005, focusing
						on the image. and convey the lifestyle of "ambitious, challenging
						and innovative"
					</Paragraph>
				</Col>
			</Row>
		</Col>
	)

	const _2008 = (
		<Col zIndex={'3'}>
			<DateText mount={triggeredPoint[1]}>2008</DateText>

			<Row align={'baseline'}>
				<Col
					margin={leftPadding}
					align={'flex-end'}
					mount={mount[1]}
					animationIn={'fadeInLeft'}
					animationOut={'fadeOutLeft'}
				>
					<SubText textAlign={'right'}>September</SubText>
					<Paragraph textAlign={'right'}>
						SNOW No.1 sales in China and worldwide since 2008.
					</Paragraph>
				</Col>

				<Point ref={(ref) => (points.current[1] = ref)} />

				<Col
					margin={rightPadding}
					align={'flex-start'}
					mount={mount[1]}
					animationIn={'fadeInRight'}
					animationOut={'fadeOutRight'}
				>
					<SubText>September</SubText>
					<Paragraph textAlign={'left'}>
						SNOW The world's number 1 selling beer since 2008.
					</Paragraph>
				</Col>
			</Row>
		</Col>
	)

	const _2021 = (
		<Col zIndex={'3'}>
			<DateText mount={triggeredPoint[2]}>2021</DateText>

			<Row align={'baseline'}>
				<Col
					margin={leftPadding}
					align={'flex-end'}
					mount={mount[2]}
					animationIn={'fadeInLeft'}
					animationOut={'fadeOutLeft'}
				>
					<SubText textAlign={'right'}>October</SubText>
					<Paragraph textAlign={'right'}>
						THE CHAMPION BEERS OF ASIA ORITAG CHAMPION WHEAT BEER OF ASIA LOWEN
						WHITEBEER, CHINA RESOURCES SNOW BEER (CHN) A witbier with a soft,
						smooth and refreshing taste. Selected sunny orchard dried orange
						peels and corianders bring out the natural sunshine goodness. The
						judges verdict: “Delicious. Very refreshing. Medium bodied with a
						full flavour of banana & clove.” JOE WHITE MALTING CHAMPION LAGER OF
						ASIA
					</Paragraph>
				</Col>

				<Point ref={(ref) => (points.current[2] = ref)} />

				<Col
					margin={rightPadding}
					align={'flex-start'}
					mount={mount[2]}
					animationIn={'fadeInRight'}
					animationOut={'fadeOutRight'}
				>
					<SubText>October</SubText>
					<Paragraph textAlign={'left'}>
						OPERA MASK, CHINA RESOURCES SNOW BEER (CHN) High-quality barley malt
						with a slow-roasted aroma process is used to give this beer a strong
						malt aroma combined with a subtle, but long-lasting and pleasant
						Czech Saaz hop kick. The unique decoction mashing gives the beer a
						rich and mellow body. The judges verdict: “Drinks nicely – medium
						bodied, clean, rich and refreshing.”
					</Paragraph>
				</Col>
			</Row>
			<Row align={'baseline'}>
				<Col
					margin={leftPadding}
					align={'flex-end'}
					mount={mount[3]}
					animationIn={'fadeInLeft'}
					animationOut={'fadeOutLeft'}
				>
					<SubText textAlign={'right'}>November</SubText>
					<Paragraph textAlign={'right'}>
						American Wheat Beer: GOLD – Fair Maiden, Chaba Brewing Co. (CHN)
						International Lager: GOLD – Opera Mask, China Resources Snow
						Breweries (CHN) Spice/Herb- Flavoured Beer GOLD – Dai Mei, Chaba
						Brewing Co. (CHN) Fruit Cider: SILVER – Urbrew Plumb Cider, URBREW
						(China) Craft Beer Co. (CHN)
					</Paragraph>
				</Col>

				<Point ref={(ref) => (points.current[3] = ref)} />

				<Col
					margin={rightPadding}
					align={'flex-start'}
					mount={mount[3]}
					animationIn={'fadeInRight'}
					animationOut={'fadeOutRight'}
				>
					<SubText>December</SubText>
					<Paragraph textAlign={'left'}>
						Sour Beer: GOLD – Berliner Weisse, Steppeo Brewery (CHN) New
						England/Hazy Style IPA: SILVER – Little Ankle-Biter, Crafters
						Brewing (CHN) Wild Ale: BRONZE – Perpetual Motion, Crafters Brewing
						(CHN) Witbier: GOLD – Lowen Witbier, China Resources Snow Breweries
						(CHN)
					</Paragraph>
				</Col>
			</Row>
		</Col>
	)

	document.title = 'Our Story - VW Inter Trade'

	return (
		<>
			<Section noPadding>
				<Center height={isMobile ? 'calc(90vh - 80px)' : '100vh'}>
					<SubText>The story of where it</SubText>
					<HeadText>All began</HeadText>
					<Banner src={banner} alt="" />
				</Center>
				<Filter />
			</Section>

			<Section color={color.primary} ref={backgroundRef}>
				<Container>
					{_1993}
					{_2008}
					{_2021}
					<LineContainer height={height} bg={bg} ref={line}>
						<Line />
					</LineContainer>
				</Container>
			</Section>
		</>
	)
}

const Line = styled.div`
	width: 5px;
	background-color: ${color.white};
	height: 100%;
`

const LineContainer = styled.div.attrs((props) => ({
	style: {
		height: `${
			props.height > props.bg * 0.9
				? `${props.bg * 0.9}px`
				: `${props.height}px`
		}`,
	},
}))`
	width: 5px;
	position: absolute;
	top: 5%;
	left: 50%;
	z-index: 0;
	transform: translateX(-50%);
	transfrom-origin: top;
	transition: 0.5s ease;
`

const Point = styled.div`
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: ${color.active};
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 0;
	transform: translate(-50%, 150%);
	transition: 1s ease-out;
`

const DateText = styled.h6`
	background-color: ${color.primary};
	width: 100%;
	height: auto;
	color: ${color.white};
	font-size: 50px;
	text-align: center;
	opacity: ${(props) => (props.mount ? '1' : '0')};
	transform: ${(props) => (props.mount ? 'scale(1)' : 'scale(0)')};
	transition: 0.5s ease-out;

	@media screen and (max-width: 1023px) {
		font-size: 40px;
	}
`
