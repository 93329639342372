import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, List, Row, Section, VWRow } from './Global'
import { ReactComponent as VWLogo } from '../../assets/VW-Logo.svg'
import {
	faYoutube,
	faFacebook,
	faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { useContext } from 'react'
import { Variable } from '../../App'
import DelayedLink from './DelayedLink'

export default function Footer() {
	const { isMobile } = useContext(Variable)
	const header = isMobile && { style: { fontSize: '24px' } }
	const ourInfo = (
		<Col
			as="ul"
			align={'baseline'}
			{...(!isMobile ? { noPadding: true } : { padding: '0 20px' })}
		>
			<li {...header}>Our Information</li>
			<Row justify={'flex-start'}>
				<FontAwesomeIcon className="icon" icon={'location-dot'} />
				<List>
					No. s25012, 25th floor, Mitrtown office tower, 944 Rama 4 road,
					Wangmai, Pathumwan, Bangkok 10330
				</List>
			</Row>
			<Row justify={'flex-start'}>
				<FontAwesomeIcon className="icon" icon={'envelope'} />
				<List>info@vw-intertrade.com</List>
			</Row>
			<Row justify={'flex-start'}>
				<FontAwesomeIcon className="icon" icon={'phone'} />
				<List>+66 2079 1824</List>
			</Row>
		</Col>
	)

	const iconSize = { height: '30px', width: 'auto', marginBottom: '10px' }

	return (
		<Section zIndex={'6'}>
			<Container>
				<Col>
					<Row
						width={'100%'}
						align={'baseline'}
						{...(isMobile && { transform: 'translateX(20px)' })}
					>
						<Col as="ul" align={'baseline'}>
							<li {...header}>USEFUL</li>
							<List>
								<DelayedLink to={'/'}>HOME</DelayedLink>
							</List>
							<List>
								<DelayedLink to={'/products/beer'}>BEER</DelayedLink>
							</List>
							<List>
								<DelayedLink to={'/products/mushroom'}>MUSHROOM</DelayedLink>
							</List>
							<List>
								<DelayedLink to={'/our-story'}>OUR STORY</DelayedLink>
							</List>
							<List>
								<DelayedLink to={'/contact'}>CONTACT US</DelayedLink>
							</List>
						</Col>

						<Col as="ul" align={'baseline'}>
							<li {...header}>Products</li>
							<List>
								<DelayedLink to={'/products/beer'}>BEER</DelayedLink>
							</List>
							<List>
								<DelayedLink to={'/products/mushroom'}>MUSHROOM</DelayedLink>
							</List>
						</Col>

						<Col as="ul" align={'baseline'}>
							<li {...header}>Contact</li>
							<List>
								<DelayedLink to={'/contact'}>Contact</DelayedLink>
							</List>
						</Col>

						{!isMobile && <>{ourInfo}</>}
					</Row>
					{isMobile && <Row>{ourInfo}</Row>}
				</Col>
				<Col>
					<Row width={'45%'} justify={'space-around'} noPadding>
						<a href="https://www.facebook.com/SnowThailandTH">
							<FontAwesomeIcon style={iconSize} icon={faFacebook} />
						</a>
						<a href="https://www.instagram.com/snowthailand.official/">
							<FontAwesomeIcon style={iconSize} icon={faInstagram} />
						</a>
					</Row>
					<VWRow padding={'10px 0 7.5px 0'}>
						<span style={{ fontSize: '16px' }}>
							{new Date().getFullYear()} © VW Inter Trade Co., Ltd. ALL RIGHTS
							RESERVED.
						</span>
						<VWLogo className="logo" />
					</VWRow>
				</Col>
			</Container>
		</Section>
	)
}
