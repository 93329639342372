import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Variable } from '../../App'
import { color, VWRow } from './Global'
import { ReactComponent as VWLogo } from '../../assets/VW-Logo.svg'

const Canvas = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 1000000000000;
`

const Loader = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${color.dark};
	transition: 0.5s ease-in-out;
	opacity: ${(props) => (props.comeIn ? '1' : '0')};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${color.white};
	font-size: 20px;
`

const LoaderWrapper = styled.div`
	width: 50%;
	height: 100%;
	margin: auto;
	position: relative;

	.loader {
		width: 75px;
		opacity: 0.15;
		height: auto;
		animation: bouncing 3s linear infinite both;
		color: ${color.white};
		margin-bottom: 15px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform-origin: 0 0;
		z-index: 1;
	}

	.loader-inner {
		width: 50px;
		opacity: 0.75;
		height: auto;
		animation: bouncing 3s linear infinite both;
		color: ${color.active};
		margin-bottom: 15px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform-origin: 0 0;
		z-index: 2;
	}

	span {
		position: absolute;
		top: 65%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.logo {
		width: 35px;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
		opacity: 0.9;

		path {
			fill: ${color.dark};
		}
	}

	@keyframes bouncing {
		0% {
			transform: scale(1) translate(-50%, -50%);
		}
		20% {
			transform: scale(1.2) translate(-50%, -50%);
		}
		50% {
			transform: scale(1) translate(-50%, -50%);
		}
		70% {
			transform: scale(1.2) translate(-50%, -50%);
		}
		100% {
			transform: scale(1) translate(-50%, -50%);
		}
	}
`

const Loading = () => {
	const [comeIn, setComeIn] = useState(false)
	const [point, setPoint] = useState(null)
	const { isClickNewPage } = useContext(Variable)

	useEffect(() => {
		if (isClickNewPage) {
			setComeIn(true)
		} else {
			setTimeout(() => setComeIn(false), 500)
		}
	}, [isClickNewPage])

	useEffect(() => {
		if (point === null) {
			setPoint(0)
		}

		if (point === 0) {
			setInterval(
				() =>
					setPoint((prev) => {
						prev = prev + 1
						if (prev > 3) {
							return 1
						}
						return prev
					}),
				1000
			)
		}
	}, [point])

	return (
		<Canvas>
			<Loader comeIn={comeIn}>
				<LoaderWrapper>
					<VWLogo className="logo" />
					<FontAwesomeIcon className="loader" icon={'circle'} />
					<FontAwesomeIcon className="loader-inner" icon={'circle'} />
					<span>Loading{'.'.repeat(point)}</span>
				</LoaderWrapper>
			</Loader>
			<VWRow padding={'10px 0 7.5px 0'}>
				<span style={{ fontSize: '16px' }}>
					{new Date().getFullYear()} © VW Inter Trade Co., Ltd. ALL RIGHTS
					RESERVED.
				</span>
				<VWLogo className="logo" />
			</VWRow>
		</Canvas>
	)
}

export default Loading
