import {
	color,
	Container,
	Filter,
	IntroBanner,
	Row,
	Col,
	SubText,
	HeadText,
	Section,
	AdjustableBox,
	OutroBanner,
	Paragraph,
	ResponsiveBox,
	BreakLine,
	Bullets,
	LeftArrow,
	RightArrow,
	ExampleCol,
	ColTarget,
} from '../utilities/Global'
import Banner from '../../assets/beer/Banner.png'
import PopupL from '../../assets/beer/Popup-Large.png'
import PopupM from '../../assets/beer/Popup-Medium.png'
import PopupS from '../../assets/beer/Popup-Can.png'
import BeerL from '../../assets/beer/Large.png'
import BeerM from '../../assets/beer/Medium.png'
import BeerS from '../../assets/beer/Can.png'
import SevenEleven from '../../assets/7-eleven.png'
import styled, { css } from 'styled-components'

import beer from '../../assets/beer/beer.png'
import Button from '../utilities/Button'
import Example from '../utilities/Example'
import leftImg from '../../assets/beer/beer-example-1.png'
import rightImg from '../../assets/beer/Li-beer.png'
import { useContext, useState } from 'react'
import { Variable } from '../../App'
import ImageTarget from '../utilities/ImageTarget'
import Sliders from '../utilities/Sliders'
import Animation from '../utilities/Animation'

const Beer = () => {
	const { isMobile, hovering } = useContext(Variable)
	const left = {
		src: leftImg,
		child: (
			<ExampleCol>
				<SubText>2018</SubText>
				<HeadText textAlign={'right'}>
					NO.1 BEER
					<br />
					IN THE WORLD
				</HeadText>
				<Paragraph readmore={'right'}>
					SNOW 雪花 (Xuehua) has been number 1 in China and worldwide since 2008
					SNOW BEER is the group's premium beer and is popular among the younger
					generation.
					<br />
					<br />
					Snow Beer started with the 2005 "Snow Great Expedition" and "Snow
					Brave the World" campaigns with a focus on brand image. and convey a
					lifestyle of "ambitious, challenging and innovative"
				</Paragraph>
				<Filter
					color={color.black}
					opacity={hovering.left ? '50%' : '20%'}
					zIndex={'-1'}
				/>
			</ExampleCol>
		),
	}

	const right = {
		src: rightImg,
		child: (
			<ExampleCol>
				<SubText>Reward 2021</SubText>
				<HeadText textAlign={'left'}>A’ Design Award</HeadText>
				<Paragraph readmore={'left'}>
					A' Design Award and Competition is one of the World’s largest, most
					prestigious and influential design accolades, one of the highest
					achievements in design. A' Design Award Winner Logo, symbolizes
					exceptional design excellence in your products, projects and services.
				</Paragraph>
				<a href={'/'}>Read more</a>
				<Filter
					color={color.black}
					opacity={hovering.right ? '50%' : '20%'}
					zIndex={'-1'}
				/>
			</ExampleCol>
		),
	}

	const partners = [
		{
			name: '7-Eleven',
			src: SevenEleven,
			key: 'partner-1',
			href: 'https://www.7eleven.co.th/find-store',
		},
	]

	const datas = [
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={BeerL} alt={''} />
					<span className="text">Bottle Large</span>
					<span className="text-active">580Ml.</span>
				</Col>
			),
			key: 'Beer-Product-1',
		},
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={BeerM} alt={''} />
					<span className="text">Bottle Small</span>
					<span className="text-active">330Ml.</span>
				</Col>
			),
			key: 'Beer-Product-2',
		},
		{
			content: (
				<Col justify="center" align={'center'}>
					<ImageTarget size={'100%'} src={BeerS} alt={''} />
					<span className="text">Can</span>
					<span className="text-active">500Ml.</span>
				</Col>
			),
			key: 'Beer-Product-3',
		},
	]

	const [swiper, setSwiper] = useState({})
	const [activeSlide, setActiveSlide] = useState(0)

	document.title = 'Beer - VW Inter Trade'
	return (
		<>
			{/* Our Beer */}
			<Section overflow={'hidden'}>
				<IntroBanner src={Banner}>
					<Container height={'78vh'}>
						<BottleGroup isMobile={isMobile}>
							<Bottle src={PopupL} delay={'0.33s'} />
							<Bottle src={PopupM} delay={'0.66s'} />
							<Bottle src={PopupS} delay={'1s'} />
						</BottleGroup>
						<AdjustableBox right={'15%'} top={'40%'} textAlign={'right'}>
							<SubText>Find out more about</SubText>
							<HeadText>Our Beer</HeadText>
						</AdjustableBox>
					</Container>
					<Filter opacity={'40%'} zIndex={'1'} color={color.black} />
				</IntroBanner>
				<OutroBanner>
					<Container {...(isMobile && { width: '95% !important' })}>
						<Row noPadding align={'center'}>
							{partners.map((partner) => (
								<ColTarget
									as={'a'}
									href={partner.href}
									target={'_blank'}
									key={partner.key}
									width={'auto'}
								>
									<ImageTarget size={'50px'} src={partner.src} alt="" />
									<h6>{partner.name}</h6>
									<span>
										All branches
										<br />
										nationwide
									</span>
								</ColTarget>
							))}
						</Row>
					</Container>
				</OutroBanner>
			</Section>

			{/* About Us */}
			<Section color={'#192744'}>
				<Animation animateIn={'animate__fadeInDown'}>
					<Container>
						<ResponsiveBox>
							<AboutUs isMobile={isMobile} />
						</ResponsiveBox>
					</Container>
				</Animation>
			</Section>

			{/* Our Stock */}
			<Section>
				<Animation animateIn={'animate__fadeInUp'}>
					<Container>
						<LeftArrow swiper={swiper} />
						<ResponsiveBox>
							<Col
								width={isMobile ? '100%' : '50%'}
								align={isMobile ? 'center' : 'baseline'}
							>
								<SubText>Check out more of</SubText>
								<HeadText>Our Stock</HeadText>
								<Paragraph {...(isMobile && { textAlign: 'center' })}>
									Malt character and sweetness is pretty bland, with a weak
									toasty note as the highlight. Finish is drying, with a
									lingering hop character and sourness that just doesn't seem
									right.
								</Paragraph>
							</Col>
							<Sliders
								setSwiper={setSwiper}
								setActiveSlide={setActiveSlide}
								slidesPerView={isMobile ? 1 : 3}
								autoplay
								className={'products'}
							>
								{datas}
							</Sliders>
						</ResponsiveBox>
						<RightArrow swiper={swiper} />
					</Container>
				</Animation>
				<Bullets length={datas.length} activeIndex={activeSlide} />
			</Section>

			{/* Examples */}
			<Example fontSize={'2vw'} left={left} right={right} positionLeft={'5%'} />
		</>
	)
}

function AboutUs({ isMobile }) {
	return (
		<>
			{isMobile && (
				<Col>
					<SubText>Thailand</SubText>
					<HeadText textAlign={'center'}>About Snow Beer</HeadText>
				</Col>
			)}
			<ImageTarget src={beer} alt={''} size={isMobile ? '100%' : '45%'} />
			<Col
				padding={isMobile ? '20px 0 0 0' : '0 0 0 30px'}
				align={isMobile ? 'center' : 'baseline'}
			>
				{!isMobile && (
					<>
						<SubText>Thailand</SubText>
						<HeadText {...(isMobile && { textAlign: 'center' })}>
							About Snow Beer
						</HeadText>
					</>
				)}
				<Paragraph {...(isMobile && { textAlign: 'center' })}>
					雪花 No.1 in sales in China and around the world since 2008! SNOW BEER
					is the group's premium beer and is popular among the younger
					generation.
				</Paragraph>
				<br />
				<Paragraph {...(isMobile && { textAlign: 'center' })}>
					Snow Beer started with the "Snow Great Expedition" and "Snow Brave the
					World" campaigns in 2005 with an emphasis on image. and convey a
					lifestyle of "ambitious, challenging and innovative"
				</Paragraph>
				<Button
					a={'https://snowbeerth.com'}
					target={'_blank'}
					rel={'noreferrer'}
				>
					Snow Beer
				</Button>
			</Col>
		</>
	)
}

const BottleGroup = styled.div`
	width: ${(props) => (props.isMobile ? '100%' : '55%')};
	height: ${(props) => (props.isMobile ? '50%' : '65%')};
	position: absolute;
	bottom: 0;
	left: ${(props) => (props.isMobile ? '50%' : '0')};
	${(props) =>
		props.isMobile &&
		css`
			transform: translateX(-50%);
		`}
	display: flex;
	z-index: 2;
	opacity: 0.95;
`

const Bottle = styled.div`
	width: calc(100% / 3);
	height: 100%;
	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
		`}
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	animation: slideInUp 1s ease-out ${(props) => props.delay || '0s'} both;
`

export default Beer
