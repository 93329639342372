import { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Variable } from '../../App'

const DelayedLink = ({ to, children, ...props }) => {
	const navigate = useNavigate()
	const { setIsClickNewPage } = useContext(Variable)

	const onClick = (e) => {
		e.preventDefault()
		setIsClickNewPage(true)
		setTimeout(() => navigate(to), 500)
		window.scroll(0, 0)
	}

	return (
		<NavLink to={to} {...props} onClick={onClick}>
			{children}
		</NavLink>
	)
}

export default DelayedLink
